import React, { useState, useEffect } from 'react'
import Footer from './common/Footer'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SalesHeader from './common/SalesHeader';
import AMHeader from './common/AMHeader';
import RMHeader from './common/RMHeader';
import axios from 'axios';
import * as Constants from "../Constants/index"
import ResponsivePagination from 'react-responsive-pagination';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Oval from './loader/CircleLoade';
export default function RejectedReg() {
	const [isVisible, setIsVisible] = useState(false);
	const [completeReg, setCompleteReg] = useState(false)
	const [isSameAsMobile, setIsSameAsMobile] = useState(false);
	const [deliverySameAsPermanent, setDeliverySameAsPermanent] = useState(false);
	const [bankDetailShow, setBankDetailShow] = useState('')
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState();
	const [searchRecord, setSearchRecord] = useState()
	const [limit] = useState(10)
	const [errors, setErrors] = useState({});
	const [status, setStatus] = useState('')
	const [loading, setLoading] = useState(false)
	const [searchUser, setSearchUser] = useState('')


	// api data
	const [results, setResults] = useState([])
	const [idData, setIdData] = useState("")
	const [stateFetchPer, setStateFetchPer] = useState([])
	const [districtFetchPer, setDistrictFetchPer] = useState([])
	const [cityFetchPer, setCityFetchPer] = useState([])
	const [stateFetch, setStateFetch] = useState([])
	const [districtFetch, setDistrictFetch] = useState([])
	const [cityFetch, setCityFetch] = useState([])

	//basic details
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [mobileWhatsApp, setMobileWhatsApp] = useState('');
	const [dob, setDob] = useState('');
	const [doa, setDoa] = useState('');
	const [marital, setMarital] = useState('');

	// user model KYC state
	const [photo, setPhoto] = useState('')
	const [aadharNumber, setAadharNumber] = useState('');
	const [mobileAadhar, setMobileAadhar] = useState('')
	const [aadharFrontUrl, setAadharFrontUrl] = useState('')
	const [aadharBackUrl, setAadharBackUrl] = useState('')
	const [panNumber, setPanNumber] = useState('');
	const [panFrontUrl, setPanFrontUrl] = useState('')

	//address permanent
	const [addressPer1, setAddressPer1] = useState('')
	const [addressPer2, setAddressPer2] = useState('')
	const [addressPer3, setAddressPer3] = useState('')
	const [cityPer, setCityPer] = useState('')
	const [cityPerId, setCityPerId] = useState(0)
	const [districtPer, setDistrictPer] = useState('')
	const [districtPerId, setDistrictPerId] = useState(0)
	const [statePer, setStatePer] = useState('')
	const [statePerId, setStatePerId] = useState(0)
	const [pincodePer, setPincodePer] = useState('')

	// address work
	const [address1, setAddress1] = useState('')
	const [address2, setAddress2] = useState('')
	const [address3, setAddress3] = useState('')
	const [state, setState] = useState('')
	const [stateId, setStateId] = useState(0)
	const [district, setDistrict] = useState('')
	const [districtId, setDistrictId] = useState(0)
	const [city, setCity] = useState('')
	const [cityId, setCityId] = useState(0)
	const [pincode, setPincode] = useState('')


	//bank details
	const [accountNo, setAccountNo] = useState('')
	const [accountType, setAccountType] = useState('')
	const [ifsc, setIfsc] = useState('')
	const [bankName, setBankName] = useState('')
	const [holderName, setHolderName] = useState('')
	const [branch, setBranch] = useState('')
	const [cancelChk, setCancelChk] = useState('')
	const [upiId, setUpiId] = useState('')
	const [upiIdScreen, setUpiIdScreen] = useState('')
	const [productValue, setInputValues] = useState(Array(6).fill(''));

	// const selectRef = useRef(null);
	// const selectRef2 = useRef(null);

	//Influencer Professional Information
	const [profession, setProfession] = useState('')
	const [contractorMobile, setContractorMobile] = useState('')
	// const [teamsize, setTeamsize] = useState('')
	const [noOfSupervisor, setNoOfSupervisor] = useState('')
	const [annualYearly, setAnnualYearly] = useState()
	const [avgNoSite, setAvgNoSite] = useState('')

	//Major Brands Used
	const [brand, setBrand] = useState([]);
	const [otherBrand, setOtherBrand] = useState('')


	//Type and Value of Products Used on Annual Basis
	const [product, setProduct] = useState([])

	const p = ["Furniture Fittings", "Kitchen Fittings", "Sliding Fittings", "Architectural Hardware", "AH - DL", "Glass Fittings"];
	// Type of Projects
	const [typeofProjects, setTypeofProjects] = useState('')

	// Solution Category (Does Turnkey Project)
	const [category, setCategory] = useState([])

	// Top 3 Dealers / Retailers You Buy From
	const [dealer1, setDealer1] = useState('')
	const [dealer2, setDealer2] = useState('')
	const [dealer3, setDealer3] = useState('')

	// If Sub Dealer, Mention Sub Dealer
	const [subDealerName, setSubDealerName] = useState('')
	const [rejectStatus] = useState(['REJECT', 'REJECTED'])

	const formattedDob = moment(dob).format('DD-MM-YYYY')
	const formattedDoa = marital === 'single' ? '' : moment(doa).format('DD-MM-YYYY');

	useEffect(() => {

		window.addEventListener('scroll', handleScroll);
		let token = localStorage.getItem('token');
		rejectededRegistrationData(searchUser);

		//rejected registration data 
		// if (currentPage !== 1) {
		// } else {
		// 	rejectededRegistrationData('');
		// }

		//state data
		try {
			axios.get(Constants.baseUrl + `master/geography/state?limit=1000`, {
				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
				}
			})
				.then((res) => {
					if (res.status === 201) {
						setStateFetchPer(res.data.output.results)
						setStateFetch(res.data.output.results)

					} else {
						// swal(res.data.msg);
					}
				})

		} catch (error) {
			console.log(error)
		}
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, status, completeReg]);

	const rejectededRegistrationData = async (userName) => {
		const searchUserVal = userName ? `&mobile=${userName}` : "";
		try {
			setLoading(true)
			axios.get(Constants.baseUrl + `user/usersBySalesTeam/list?userType=MEMBER&status=${rejectStatus}${searchUserVal}&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`, {
				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
					appVersion: Constants.av,
					platform: Constants.pt,
					company: Constants.company,
					program: Constants.program,
				}
			})
				.then((res) => {
					if (res.status === 201) {
						if (res.data.code === 10001) {
							setLoading(false)
							setResults(res.data.output.results)
							setTotalPages(res.data.output.totalPages);
						} else if (res.data.code === 12002) {
							setLoading(false)
							setSearchRecord(res.data.output.results)
							setTotalPages(res.data.output.totalPages);
						} else {
							swal({
								text: res.data.msg,
								// icon: 'error',
								timer: 2000,
								buttons: false,
							})
						}
					} else {
						swal({
							text: res.data.msg,
							// icon: 'error',
							timer: 2000,
							buttons: false,
						})
					}
				})
		} catch (error) {
			console.log(error)
		}
	}



	const handleInputChange = (index, value) => {
		if (/^\d*$/.test(value)) {
			const newInputValues = [...productValue];
			newInputValues[index] = value;
			setInputValues(newInputValues);
		}
	};
	// call state api by state ID for get permanent district name
	const handleStateChangePer = (event) => {
		const selectedId = event.target.value
		setDistrictFetchPer([])
		setCityFetchPer([])
		setDistrictPer('')
		setCityPer('')
		setPincodePer('')
		if (selectedId === '0') {
			// alert('hello')
		} else {
			districtDataPer(selectedId);
		}
		// districtDataPer(selectedId);

	};

	let token = localStorage.getItem('token');
	const districtDataPer = async (stateid) => {
		try {
			await axios.get(Constants.baseUrl + `master/geography/state/${stateid}`, {
				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
				}
			})
				.then((res) => {
					if (res.status === 200) {
						if (res.data.code === 10001) {
							setStatePer(res.data.output.name)
							setStatePerId(res.data.output.id)
							setDistrictFetchPer(res.data.output.districts)

						} else {
							// swal(res.data.msg)
						}
					} else {
						// swal(res.data.msg);
					}
				})

		} catch (error) {
			console.log(error)
		}
	}

	// call district api by district id for get permanent city name
	const handleDistrictChangePer = async (event) => {
		const selectedId = event.target.value
		setCityFetchPer([])
		setCityPer('')
		setPincodePer('')
		if (selectedId === '0') {
			// alert('hello')
		} else {
			cityDataPer(selectedId);
		}
		// cityDataPer(selectedId);

	};

	const cityDataPer = async (cityid) => {
		try {
			await axios.get(Constants.baseUrl + `master/geography/district/${cityid}`, {
				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
				}
			})
				.then((res) => {
					if (res.status === 200) {
						if (res.data.code === 10001) {
							setDistrictPer(res.data.output.name)
							setDistrictPerId(res.data.output.id)
							setCityFetchPer(res.data.output.cities)

						} else {
							// swal(res.data.msg)
						}
					} else {
						// swal(res.data.msg);
					}
				})

		} catch (error) {
			console.log(error)
		}
	}


	// call state api by state ID for get delivery district name
	const handleStateChange = async (event) => {
		const selectedId = event.target.value
		setDistrictFetch([])
		setCityFetch([])
		setDistrict('')
		setCity('')
		setPincode('')
		if (selectedId === '0') {
			// alert('hello')
		} else {
			districtData(selectedId);
		}
	};

	const districtData = async (stateid) => {
		try {
			await axios.get(Constants.baseUrl + `master/geography/state/${stateid}`, {
				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
				}
			})
				.then((res) => {
					if (res.status === 200) {
						if (res.data.code === 10001) {
							setState(res.data.output.name)
							setStateId(res.data.output.id)
							setDistrictFetch(res.data.output.districts)

						} else {
							// swal(res.data.msg)
						}
					} else {
						// swal(res.data.msg);
					}
				})

		} catch (error) {
			console.log(error)
		}
	}

	// call district api by district id for get delivery city name
	const handleDistrictChange = async (event) => {
		const selectedId = event.target.value
		setCityFetch([])
		setCity('')
		setPincode('')
		if (selectedId === '0') {
			// alert('hello')
		} else {
			cityData(selectedId);
		}
	};

	const cityData = async (cityid) => {
		try {
			await axios.get(Constants.baseUrl + `master/geography/district/${cityid}`, {
				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
				}
			})
				.then((res) => {
					if (res.status === 200) {
						if (res.data.code === 10001) {
							setDistrict(res.data.output.name)
							setDistrictId(res.data.output.id)
							setCityFetch(res.data.output.cities)

						} else {
							// swal(res.data.msg)
						}
					} else {
						// swal(res.data.msg);
					}
				})

		} catch (error) {
			console.log(error)
		}
	}


	const handleCompleteReg = async (id) => {
		let token = localStorage.getItem('token');
		try {
			await axios.get(Constants.baseUrl + `user/manage/${id}`, {
				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
					appVersion: Constants.av,
					platform: Constants.pt,
					company: Constants.company,
					program: Constants.program
				},
			})
				.then((res) => {
					if (res.status === 200) {
						if (res.data.code === 10001) {
							setCompleteReg(true);
							window.scrollTo({
								top: 500,
								behavior: 'smooth',
							});
							const data = res.data.output;
							setIdData(data);
							// Set values for basic details
							setName(data.name);
							setEmail(data.email);
							setMobile(data.mobile);
							setMobileWhatsApp(data.mobileWhatsApp);
							if (mobile === mobileWhatsApp) {
								setIsSameAsMobile(true)
							} else {
								setIsSameAsMobile(false)
							}
							setMarital(data.marital)

							let dobString1 = data.dob;

							if (moment(dobString1, 'DD-MM-YYYY', true).isValid()) {
								const dobString = dobString1?.split('-');
								const date = dobString[1];
								const month = dobString[0];
								const year = dobString[2]
								const fullDate = `${date}-${month}-${year}`;
								setDob(new Date(fullDate))
							}


							let doaString1 = data.doa;

							if (moment(doaString1, 'DD-MM-YYYY', true).isValid()) {
								const doaString = doaString1?.split('-');
								const date = doaString[1];
								const month = doaString[0];
								const year = doaString[2]
								const fullDate = `${date}-${month}-${year}`;
								setDoa(new Date(fullDate))
							}
							setPhoto(data.userModelKYC.photo);
							setAadharNumber(data.userModelKYC.aadharNumber);
							setMobileAadhar(data.userModelKYC.aadharMobile)
							setAadharFrontUrl(data.userModelKYC.aadharFrontUrl);
							setAadharBackUrl(data.userModelKYC.aadharBackUrl);
							setPanNumber(data.userModelKYC.panNumber);
							setPanFrontUrl(data.userModelKYC.panFrontUrl);

							// Set values for address work
							setAddress1(data.addressWork.workAddress1);
							setAddress2(data.addressWork.workAddress2);
							setAddress3(data.addressWork.workAddress3);
							setCity(data.addressWork.workCity);
							setCityId(data.addressWork.workCityId)
							setDistrict(data.addressWork.workdistrict);
							setDistrictId(data.addressWork.workdistrictId)
							setState(data.addressWork.workState);
							setStateId(data.addressWork.workStateId)
							setPincode(data.addressWork.workPincode);

							// Set values for address permanent
							setAddressPer1(data.addressPerm.permAddress1);
							setAddressPer2(data.addressPerm.permAddress2);
							setAddressPer3(data.addressPerm.permAddress3);
							setCityPer(data.addressPerm.permCity);
							setCityPerId(data.addressPerm.permCityId)
							setDistrictPer(data.addressPerm.permdistrict);
							setDistrictPerId(data.addressPerm.permdistrictId)
							setStatePer(data.addressPerm.permState);
							setStatePerId(data.addressPerm.permStateId)
							setPincodePer(data.addressPerm.permPincode);
							if (data.addressPerm.permAddress1 === data.addressWork.workAddress1 && data.addressPerm.permAddress2 === data.addressWork.workAddress2 && data.addressPerm.permAddress3 === data.addressWork.workAddress3 &&
								data.addressPerm.permState === data.addressWork.workState && data.addressPerm.permdistrict === data.addressWork.workdistrict && data.addressPerm.permCity === data.addressWork.workCity && data.addressPerm.permPincode === data.addressWork.workPincode) {
								setDeliverySameAsPermanent(true)
							} else {
								setDeliverySameAsPermanent(false)
							}
							// Additional condition: if permAddress1 is blank, set delivery address not the same.
							if (!data.addressPerm.permAddress1) {
								setDeliverySameAsPermanent(false);
							}

							// Set values for bank details
							setAccountNo(data.bankDetail.bankAccountNo);
							setAccountType(data.bankDetail.accountType);
							setIfsc(data.bankDetail.ifsc);
							setBankName(data.bankDetail.bankName);
							setHolderName(data.bankDetail.holderName);
							setBranch(data.bankDetail.branchName);
							setCancelChk(data.bankDetail.cancelChk);
							setUpiId(data.bankDetail.upiId);
							setUpiIdScreen(data.bankDetail.upiIdScreen);

							if (data.bankDetail.upiId === '' && data.bankDetail.upiIdScreen === '') {
								setBankDetailShow('bankdetails')
							} else {
								setBankDetailShow('upidetails')
							}

							//Influencer Professional Information
							setProfession(data.userDetails.profession)
							setContractorMobile(data.userDetails.contractMobile)
							setNoOfSupervisor(data.userDetails.supervisors)
							setAnnualYearly(data.userDetails.annualYearly)
							setAvgNoSite(data.userDetails.noOfSites)

							setBrand(data.userDetails.brandsUsed.split(','))
							setProduct(data.userDetails.productType.split(','))
							setInputValues(data.userDetails.productValue.split(','))
							setTypeofProjects(data.userDetails.typeofProjects)
							setCategory(data.userDetails.solutionCategory.split(','))

							let brands = data.userDetails.brandsUsed.split(',');
							let itemsStartingWithSearchString = brands.filter(brands => brands.startsWith('Others'));
							// console.log(itemsStartingWithSearchString);

							let others = itemsStartingWithSearchString.pop();
							// console.log(others);
							let otherBrand = others.split('|')[1];
							// console.log(otherBrand);

							setOtherBrand(otherBrand);
							// alert(otherBrand);


							setDealer1(data.userDetails.dealer1)
							setDealer2(data.userDetails.dealer2)
							setDealer3(data.userDetails.dealer3)
							setSubDealerName(data.userDetails.subDealer)


						} else if (res.data.code === 12001) {
							swal({
								text: res.data.msg,
								timer: 2000,
								buttons: false,
							});
						}
					} else {
						swal({
							text: res.data.msg,
							timer: 2000,
							buttons: false,
						});
					}
				});
		} catch (error) {
			console.log(error)
		}
	}

	// registration form validation
	const validateForm = () => {

		let errors = {};

		if (!name) {
			errors.name = ' Influencer name required';
		} else if (name.length < 3) {
			errors.name = 'Influencer name must be at least 3 characters';
		}
		if (!dob) {
			errors.dob = 'DOB Required';
		}
		if (marital === 'married') {
			if (!doa) {
				errors.doa = 'DOA Required';
			}
		}
		if (!email) {
			errors.email = 'E-Mail ID Required';
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			errors.email = 'E-Mail ID Invalid';
		}
		if (!mobile) {
			errors.mobile = 'Mobile no. required';
		} else if (!/^[6-9]\d{9}$/.test(mobile)) {
			errors.mobile = 'Invalid mobile no.';
		} else if (mobile.length !== 10) {
			errors.mobile = 'Mobile no. must be 10 digits';
		}
		if (!mobileWhatsApp) {
			errors.mobileWhatsApp = 'WhatsApp no. required';
		} else if (!/^[6-9]\d{9}$/.test(mobileWhatsApp)) {
			errors.mobileWhatsApp = 'Invalid whatsapp no.';
		} else if (mobileWhatsApp.length !== 10) {
			errors.mobileWhatsApp = 'Whatsapp no. must be 10 digits';
		}
		if (!addressPer1) {
			errors.addressPer1 = 'House / Flat / Block No. Required'
		}
		if (!address2) {
			errors.address2 = 'Address 2 is required'
		}
		if (!state) {
			errors.state = 'State is required'
		}
		if (!district) {
			errors.district = 'District is required'
		}
		if (!city) {
			errors.city = 'City is required'
		}
		if (!pincode) {
			errors.pincode = ' Pincode is required';
		} else if (!/^\d+$/.test(pincode)) {
			errors.pincode = 'Pincode must be numeric';
		} else if (pincode.length !== 6) {
			errors.pincode = 'Pincode must be 6 digits';
		}
		if (!addressPer1) {
			errors.addressPer1 = 'House / Flat / Block No. Required'
		}
		if (!addressPer2) {
			errors.addressPer2 = 'Street / Colony / Locality Name Required'
		}
		if (!statePer) {
			errors.statePer = 'State Required'
		}
		if (!districtPer) {
			errors.districtPer = 'District Required'
		}
		if (!cityPer) {
			errors.cityPer = 'City Required'
		}
		if (!pincodePer) {
			errors.pincodePer = ' Pin Code Required';
		} else if (!/^\d+$/.test(pincodePer)) {
			errors.pincodePer = 'Pin Code must be numeric';
		} else if (pincodePer.length !== 6) {
			errors.pincodePer = 'Pin Code must be 6 digits';
		}
		if (!address1) {
			errors.address1 = 'House / Flat / Block No. Required'
		}
		if (!address2) {
			errors.address2 = 'Street / Colony / Locality Name Required'
		}
		if (!state) {
			errors.state = 'State Required'
		}
		if (!district) {
			errors.district = 'District Required'
		}
		if (!city) {
			errors.city = 'City Required'
		}
		if (!pincode) {
			errors.pincode = ' Pin Code Required';
		} else if (!/^\d+$/.test(pincode)) {
			errors.pincode = 'Pin Code must be numeric';
		} else if (pincode.length !== 6) {
			errors.pincode = 'Pin Code must be 6 digits';
		}
		if (!photo) {
			errors.photo = 'Please select Image'
		}
		if (!aadharFrontUrl) {
			errors.aadharFrontUrl = 'Please select Image'
		}
		if (!aadharBackUrl) {
			errors.aadharBackUrl = 'Please select Image'
		}
		if (!aadharNumber) {
			errors.aadharNumber = 'Aadhar No. Required'
		} else if (aadharNumber.length !== 12) {
			errors.aadharNumber = 'Aadhar No. must be 12 digits';
		} else if (!/^\d+$/.test(aadharNumber)) {
			errors.aadharNumber = 'Aadhar No. must be numeric';
		}
		// if (!panNumber) {
		// 	errors.panNumber = 'PAN no. required';
		// } else 
    
    
    if (panNumber && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(panNumber)) {
			errors.panNumber = 'Invalid PAN format';
		}
		// if (!panFrontUrl) {
		// 	errors.panFrontUrl = 'Please select Image'
		// }


		// if (!holderName) {
		// 	errors.holderName = 'Account holder name required';
		// } else if (holderName.length < 3) {
		// 	errors.holderName = 'Account holder name must be at least 3 characters';
		// }
		// if (!accountType) {
		// 	errors.accountType = 'Account Type Required';
		// }
		// if (!bankName) {
		// 	errors.bankName = 'Bank Name Required';
		// }
		// if (!accountNo) {
		// 	errors.accountNo = 'Account no. required';
		// } else if (!/^\d+$/.test(accountNo)) {
		// 	errors.accountNo = 'Account no. must be numeric';
		// } else if (accountNo.length < 5) {
		// 	errors.accountNo = 'Account no. must be at least 5 characters';
		// }
		// if (!branch) {
		// 	errors.branch = 'Branch name required';
		// } else if (branch.length < 2) {
		// 	errors.name = 'branch name must be at least 2 characters';
		// }
		// if (!ifsc) {
		// 	errors.ifsc = 'IFSC Code Required';
		// } else if (ifsc.length !== 11) {
		// 	errors.ifsc = 'IFSC Code must be 11 digits';
		// }
		// if (!cancelChk) {
		// 	errors.cancelChk = 'Please select Image';
		// }


		// if (!upiId) {
		// 	errors.upiId = 'UPI ID Required';
		// } if (!upiIdScreen) {
		// 	errors.upiIdScreen = 'Please select Image';
		// }


		return errors;
	};

	// bankdetails form validation
	const validateBankForm = () => {

		let errors = {};
		if (!holderName) {
			errors.holderName = 'Account holder name required';
		} else if (holderName.length < 3) {
			errors.holderName = 'Account holder name must be at least 3 characters';
		}
		if (!accountType) {
			errors.accountType = 'Account Type Required';
		}
		if (!bankName) {
			errors.bankName = 'Bank Name Required';
		}
		if (!accountNo) {
			errors.accountNo = 'Account no. required';
		} else if (!/^\d+$/.test(accountNo)) {
			errors.accountNo = 'Account no. must be numeric';
		} else if (accountNo.length < 5) {
			errors.accountNo = 'Account no. must be at least 5 characters';
		}
		if (!branch) {
			errors.branch = 'Branch name required';
		} else if (branch.length < 2) {
			errors.name = 'branch name must be at least 2 characters';
		}
		if (!ifsc) {
			errors.ifsc = 'IFSC Code Required';
		} else if (ifsc.length !== 11) {
			errors.ifsc = 'IFSC Code must be 11 digits';
		}
		if (!cancelChk) {
			errors.cancelChk = 'Please select Image';
		}

		return errors;
	};
	//upi details validation
	const validateUPI = () => {

		let errors = {};
		if (!upiId) {
			errors.upiId = 'UPI ID Required';
		} if (!upiIdScreen) {
			errors.upiIdScreen = 'Please select Image';
		}
		return errors;
	};

	//call register api for plumber registration
	//call api for plumber update with status VERIFIED
	const register = async (id) => {
		if (marital === 'single') {
			setDoa('')
		}
		let token = localStorage.getItem('token');
		setErrors({});

		const validationErrors = validateForm();
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return; // Don't proceed with the API call if there are validation errors
		}
		      let bankData =
            holderName &&
            accountNo &&
            accountType &&
            branch &&
            ifsc &&
            cancelChk;
          let upiData = upiId && upiIdScreen;

          // Check if at least one of the data sets is present
          if (bankData || upiData) {
            if (bankData) {
              const bankErrors = validateBankForm();
              if (Object.keys(bankErrors).length > 0) {
                setErrors(bankErrors);
                return; // Prevent API call if bank validation fails
              }
            }

            if (upiData) {
              const upiErrors = validateUPI();
              if (Object.keys(upiErrors).length > 0) {
                setErrors(upiErrors);
                return; // Prevent API call if UPI validation fails
              }
            }
          } else {
            swal("Please Fill Bank Details or UPI Details");
            return;
          }
		
		// else if (bankDetailShow === 'bankdetails') {
		// 	const validationErrors = validateBankForm();
		// 	if (Object.keys(validationErrors).length > 0) {
		// 		setErrors(validationErrors);
		// 		return; // Don't proceed with the API call if there are validation errors
		// 	}
		// } else if (bankDetailShow === 'upidetails') {
		// 	const validationErrors = validateUPI();
		// 	if (Object.keys(validationErrors).length > 0) {
		// 		setErrors(validationErrors);
		// 		return; // Don't proceed with the API call if there are validation errors
		// 	}
		// }
		try {
			let brandIndex = brand.indexOf('Others');
			let filteredBrands = brand.filter(brand => !brand.startsWith("Others"));
			setBrand(filteredBrands);
			let newBrand = brand;
			if (otherBrand !== '') {
				if (brandIndex === -1) {
					newBrand = [...brand];
					newBrand.push('Others' + "|" + otherBrand);
				}
				else {
					newBrand = [...brand];
					newBrand.splice(brandIndex, 1);
					newBrand.push('Others' + "|" + otherBrand);

				}
			}
			await axios.patch(Constants.baseUrl + `user/manage/${id}`, {
				name: name,
				email: email,
				mobile: mobile,
				mobileWhatsApp: mobileWhatsApp,
				dob: formattedDob,
				doa: formattedDoa,
				// dob: moment(dob).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
				// doa: moment(doa).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
				marital: marital,
				status: 'VERIFIED',
				userModelKYC: {
					photo: photo,
					aadharMobile: mobileAadhar,
					aadharNumber: aadharNumber,
					aadharFrontUrl: aadharFrontUrl,
					aadharBackUrl: aadharBackUrl,
					aadharVerify: 0,
					aadharVerifiedBy: '',
					aadharVerifiedOn: '2023-06-03T12:34:47.943Z',
					panNumber: panNumber,
					panFrontUrl: panFrontUrl,
					panVerify: 0,
					panVerifiedBy: '',
					panVerifiedOn: '2023-06-03T12:34:47.943Z'
				},
				addressWork: {
					workAddress1: address1,
					workAddress2: address2,
					workAddress3: address3,
					workCity: city,
					workCityId: cityId,
					workState: state,
					workStateId: stateId,
					workdistrict: district,
					workdistrictId: districtId,
					workPincode: pincode,
					workCountry: ''
				},
				addressPerm: {
					permAddress1: addressPer1,
					permAddress2: addressPer2,
					permAddress3: addressPer3,
					permCity: cityPer,
					permCityId: cityPerId,
					permState: statePer,
					permStateId: statePerId,
					permdistrict: districtPer,
					permdistrictId: districtPerId,
					permPincode: pincodePer,
					permCountry: ''
				},
				bankDetail: {
					bankAccountNo: accountNo,
					accountType: accountType,
					ifsc: ifsc,
					beneficiary: '',
					bankName: bankName,
					holderName: holderName,
					branchName: branch,
					cancelChk: cancelChk,
					upiId: upiId,
					upiIdScreen: upiIdScreen
				},
				userDetails: {
					contractMobile: contractorMobile,
					teamSize: '',
					supervisors: noOfSupervisor,
					annualYearly: annualYearly,
					noOfSites: avgNoSite,
					brandsUsed: newBrand.join(','),
					productType: product.join(','),
					productValue: productValue.join(','),
					typeofProjects: typeofProjects,
					solutionCategory: category.join(','),
					dealer1: dealer1,
					dealer2: dealer2,
					dealer3: dealer3,
					subDealer: subDealerName,
					profession: profession
				},
				userType: "MEMBER",
			}, {
				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
					appVersion: Constants.av,
					platform: Constants.pt,
					company: Constants.company,
					program: Constants.program
				},
			})
				.then((res) => {
					if (res.status === 200) {
						setCompleteReg(false)
						swal({
							text: res.data.msg,
							icon: 'success',
							timer: 2000,
							buttons: false,
						});
					} else {
						swal({
							text: res.data.msg,
							// icon: 'success',
							timer: 2000,
							buttons: false,
						});
					}
				});
		} catch (error) {
			console.log(error)
		}
	}

	const handlePhoto = (event) => {
		updatePhoto(event.target.files[0]);
	};
	const handleAadharFront = (event) => {
		updateAdhaarFront(event.target.files[0]);
	};
	const handleAadharBack = (event) => {
		updateAdhaarBack(event.target.files[0]);
	};
	const handlePanCard = (event) => {
		updatePan(event.target.files[0]);
	};
	const handleCheque = (event) => {
		updateCheque(event.target.files[0]);
	}
	const handleUpiIdScreen = (event) => {
		updateUpiIdScreen(event.target.files[0]);
	}

	const updatePhoto = (file) => {
		let token = localStorage.getItem('token');
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('type', 'photo');
			axios.post(Constants.baseUrl + 'uploader/upload', formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Accept-Language': localStorage.getItem('language'),
					platform: Constants.pt,
					appVersion: Constants.av,
					company: Constants.company,
					program: Constants.program,
				},
			}).then((res) => {
				if (res.status === 201) {
					setPhoto(res.data.output.url)
				} else {
					swal('Something went wrong!');
				}
			});
		} catch (error) {
			swal(error)
		}
	}

	const updateAdhaarFront = (file) => {
		let token = localStorage.getItem('token');
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('type', 'aadhar');
			axios.post(Constants.baseUrl + 'uploader/upload', formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Accept-Language': localStorage.getItem('language'),
					platform: Constants.pt,
					appVersion: Constants.av,
					company: Constants.company,
					program: Constants.program,
				},
			}).then((res) => {
				if (res.status === 201) {
					setAadharFrontUrl(res.data.output.url)
				} else {
					swal('Something went wrong!');
				}
			});
		} catch (error) {
			swal(error)
		}
	}

	const updateAdhaarBack = (file) => {
		let token = localStorage.getItem('token');
		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('type', 'aadhar');

			axios.post(Constants.baseUrl + 'uploader/upload', formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Accept-Language': localStorage.getItem('language'),
					platform: Constants.pt,
					appVersion: Constants.av,
					company: Constants.company,
					program: Constants.program,
				},
			}).then((res) => {
				if (res.status === 201) {
					setAadharBackUrl(res.data.output.url)

				} else {
					swal('Something went wrong!');
				}
			});
		} catch (error) {
			swal(error)
		}
	}

	const updatePan = (file) => {
		let token = localStorage.getItem('token');

		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('type', 'pan');

			axios.post(Constants.baseUrl + 'uploader/upload', formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Accept-Language': localStorage.getItem('language'),
					platform: Constants.pt,
					appVersion: Constants.av,
					company: Constants.company,
					program: Constants.program,
				},
			}).then((res) => {
				if (res.status === 201) {
					setPanFrontUrl(res.data.output.url)

				} else {
					swal('Something went wrong!');
				}
			});
		} catch (error) {
			swal(error)
		}
	}

	const updateCheque = (file) => {
		let token = localStorage.getItem('token');

		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('type', 'cheque');

			axios.post(Constants.baseUrl + 'uploader/upload', formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Accept-Language': localStorage.getItem('language'),
					platform: Constants.pt,
					appVersion: Constants.av,
					company: Constants.company,
					program: Constants.program,
				},
			}).then((res) => {
				if (res.status === 201) {
					setCancelChk(res.data.output.url)

				} else {
					swal('Something went wrong!');
				}
			});
		} catch (error) {
			swal(error)
		}
	}

	const updateUpiIdScreen = (file) => {
		let token = localStorage.getItem('token');

		try {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('type', 'upiId');

			axios.post(Constants.baseUrl + 'uploader/upload', formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Accept-Language': localStorage.getItem('language'),
					platform: Constants.pt,
					appVersion: Constants.av,
					company: Constants.company,
					program: Constants.program,
				},
			}).then((res) => {
				if (res.status === 201) {
					setUpiIdScreen(res.data.output.url)
				} else {
					swal('Something went wrong!');
				}
			});
		} catch (error) {
			swal(error)
		}
	}
	// add brands
	const addtoBrand = (name) => {
		// console.log(name)
		const brandIndex = brand.indexOf(name);

		// console.log(brandIndex)
		if (brandIndex !== -1) {

			if (name === 'Others') {
				setOtherBrand('')
				let filteredBrands = brand.filter(brand => !brand.startsWith("Others"));
				setBrand(filteredBrands);
			}
			// If the brandName already exists, remove it from the array using splice
			else {
				const newBrand = [...brand];
				newBrand.splice(brandIndex, 1);
				setBrand(newBrand);
			}
			// console.log(newBrand)
		}
		else {
			const newBrand = [...brand];
			newBrand.push(name);
			setBrand(newBrand);
			// console.log("push")
			// console.log(newBrand)
		}
	}

	const setOtherValue = (value) => {
		//  alert(value)
		setOtherBrand(value)

	}
	// add product
	const addtoProduct = (name) => {
		// alert(name)
		// console.log(name)
		const productIndex = product.indexOf(name);
		// console.log(productIndex)
		if (productIndex !== -1) {
			// If the brandName already exists, remove it from the array using splice
			const newProduct = [...product];
			newProduct.splice(productIndex, 1);
			setProduct(newProduct);
			// console.log(newProduct)
		}
		else {
			const newProduct = [...product];
			newProduct.push(name);
			setProduct(newProduct);
			// console.log("push")
			// console.log(newProduct)
		}
	}

	// add category
	const addtoCategory = (name) => {
		// console.log(name)
		const categoryIndex = category.indexOf(name);
		console.log(categoryIndex)
		if (categoryIndex !== -1) {
			// If the brandName already exists, remove it from the array using splice
			const newCategory = [...category];
			newCategory.splice(categoryIndex, 1);
			setCategory(newCategory);
			// console.log(newCategory)
		}
		else {
			const newCategory = [...category];
			newCategory.push(name);
			setCategory(newCategory);
			// console.log("push")
			// console.log(newCategory)
		}
	}


	// scroll button functionality start
	const handleScroll = () => {
		const scrollTop = window.pageYOffset;

		if (scrollTop > 200) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	// scroll button functionality end

	// address same as permanent functionality start
	const handleCheckboxChangeAdd = (event) => {
		setDeliverySameAsPermanent(event.target.checked);

		if (event.target.checked) {
			// Set delivery address to permanent address
			setAddress1(addressPer1);
			setAddress2(addressPer2);
			setAddress3(addressPer3);
			setPincode(pincodePer);
			setState(statePer);
			setStateId(statePerId)
			setDistrict(districtPer);
			setDistrictId(districtPerId)
			setCity(cityPer);
			setCityId(cityPerId)
		} else {
			// Reset delivery address inputs
			setAddress1('');
			setAddress2('');
			setAddress3('');
			setPincode('');
			setState('');
			setStateId(0)
			setDistrict('');
			setDistrictId(0)
			setCity('');
			setCityId(0)
		}
	};

	// whatsapp number same as mobile
	const handleCheckboxChange = (event) => {
		setIsSameAsMobile(event.target.checked);
		if (event.target.checked) {
			setMobileWhatsApp(mobile);
		} else {
			setMobileWhatsApp('')
		}
	};

	const handleSearchQuery = (event) => {
		const inputValue = event.target.value;
		if (/^\d{0,10}$/.test(inputValue)) {
      setSearchUser(inputValue);
	  setCurrentPage(1)
    }
		if (event.target.value === '') {
			rejectededRegistrationData('')
			setSearchRecord()
		}
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			rejectededRegistrationData(searchUser);
		}
	};
	// Calculate the exact date 18 years ago from today
	const exactMinDate = new Date();
	exactMinDate.setFullYear(exactMinDate.getFullYear() - 18);
	exactMinDate.setHours(0, 0, 0, 0);

	let headerComponent;

	if (localStorage.getItem('user_type') === 'SALES') {
		headerComponent = <SalesHeader />;
	} else if (localStorage.getItem('user_type') === 'SH') {
		headerComponent = <AMHeader />;
	} else if (localStorage.getItem('user_type') === 'RO') {
		headerComponent = <RMHeader />;
	}
	return (
    <>
      {loading ? <Oval /> : null}
      {headerComponent}
      <section className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt">
                <div className="row clearfix mb-15">
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label>Influencer Mobile</label>
                      <input
                        type="text"
                        className="form-control"
                        value={searchUser}
                        onChange={handleSearchQuery}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 col-xs-12">
                    <div className="form-group has-float-label style-two">
                      <label className="invisible" style={{ width: "100%" }}>
                        hidden
                      </label>
                      <input
                        id="btnsubmit"
                        className="btn login_btn up-side"
                        type="submit"
                        value="Submit"
                        disabled={searchUser.trim() === ""}
                        onClick={() => rejectededRegistrationData(searchUser)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt">
                <div className="row">
                  <div className="col-md-12">
                    <div className="carrd1">
                      <div className="card-header card-header-warning">
                        <h4 className="card-title">
                          Edit Rejected Registrations{" "}
                        </h4>
                      </div>
                      <div className="card-body table-responsive">
                        <div className="card-body table-responsive">
                          <div className="table table-striped mb_01">
                            <table
                              id="gridData"
                              className="table table-bordered mytable"
                              style={{ width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th scope="col">Sr.No.</th>
                                  <th scope="col"></th>

                                  <th scope="col">Regional Manager</th>
                                  {/* <th scope="col">
																		Regional Manager Mobile
																	</th>
																	<th scope="col">
																		AM Name
																	</th>
																	<th scope="col">
																		AM Name Mobile
																	</th> */}
                                  <th scope="col">Sales Person Name</th>
                                  <th scope="col">Sales Person Mobile</th>
                                  <th scope="col">Influencer Name</th>
                                  <th scope="col">Influencer Mobile.</th>
                                  <th scope="col">Influencer Email</th>
                                  <th scope="col">Added On</th>
                                </tr>
                              </thead>
                              {searchRecord === null ? (
                                <tbody>
                                  <tr>
                                    <td
                                      colSpan="12"
                                      style={{
                                        textAlign: "center",
                                        fontSize: "16px",
                                      }}
                                    >
                                      No record found!
                                    </td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>
                                  {results.map((item, index) => {
                                    const serialNumber =
                                      (currentPage - 1) * limit + index + 1;
                                    const createDate = item[9]
                                      ? moment(item[9]).format(
                                          "DD-MM-YYYY h:mm A"
                                        )
                                      : "";
                                    return (
                                      <>
                                        <tr>
                                          <td>{serialNumber}</td>
                                          <td>
                                            <span>
                                              <Link
                                                to=""
                                                className="editlink"
                                                onClick={() =>
                                                  handleCompleteReg(item[10])
                                                }
                                              >
                                                <i className="fa fa-edit"></i>{" "}
                                                Complete Registration{" "}
                                              </Link>
                                            </span>
                                          </td>
                                          <td>{item[0]}</td>
                                          {/* <td>{item[1]}</td>
																					<td>{item[2]}</td>
																					<td>{item[3]}</td> */}
                                          <td>{item[4]}</td>
                                          <td>{item[5]}</td>
                                          <td>{item[6]}</td>
                                          <td>{item[7]}</td>
                                          <td>{item[8]}</td>
                                          <td>{createDate}</td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              )}
                            </table>
                            {totalPages > 1 && (
                              <ResponsivePagination
                                current={currentPage}
                                total={totalPages}
                                onPageChange={(page) => setCurrentPage(page)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {completeReg === true ? (
                  <div id="divDetails">
                    <div className="container">
                      <div className="row clearfix">
                        <div className="col-md-12 col-xs-12">
                          <h4 className="midhead">Influencer Detail </h4>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Name *</label>
                            <input
                              autoComplete="nope"
                              className="form-control ignore"
                              maxLength="35"
                              placeholder="Name*"
                              type="text"
                              value={name}
                              onChange={(event) => {
                                const input = event.target.value;
                                if (/^[a-zA-Z\s]*$/.test(input)) {
                                  // Only alphabetic characters
                                  setName(input);
                                }
                              }}
                            />{" "}
                          </div>
                          {errors.name && (
                            <div className="error-message">{errors.name}</div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>DOB *</label>
                            <DatePicker
                              selected={
                                dob ? moment(dob, "dd-MM-YYYY").toDate() : null
                              }
                              onChange={(date) => setDob(date)}
                              dateFormat="dd-MM-yyyy "
                              className="form-control"
                              value={
                                dob ? moment(dob, "dd-MM-YYYY").toDate() : null
                              }
                              showYearDropdown
                              showMonthDropdown
                              placeholderText="dd-mm-yyyy"
                              maxDate={exactMinDate} // Up to the exact 18th birthday
                              onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                              onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                              onClick={(e) => e.preventDefault()} // Preven
                            />
                          </div>
                          {errors.dob && (
                            <div className="error-message">{errors.dob}</div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Marital Status *</label>
                            <div className="h34">
                              <label className="radio-inline">
                                <input
                                  type="radio"
                                  name="marital"
                                  value="single"
                                  checked={marital === "single"}
                                  onChange={(event) =>
                                    setMarital(event.target.value)
                                  }
                                />{" "}
                                <span> Single</span>
                              </label>
                              <label className="radio-inline">
                                <input
                                  id="chckmrd"
                                  type="radio"
                                  name="marital"
                                  value="married"
                                  checked={marital === "married"}
                                  onChange={(event) =>
                                    setMarital(event.target.value)
                                  }
                                />
                                <span> Married</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        {marital === "married" ? (
                          <div
                            className="col-md-3 col-sm-12 col-xs-12 annidtclassName"
                            id="divannidt"
                          >
                            <div className="form-group has-float-label style-two">
                              <label>Anniversary Date</label>
                              <DatePicker
                                selected={
                                  doa
                                    ? moment(doa, "dd-MM-YYYY").toDate()
                                    : null
                                }
                                onChange={(date) => setDoa(date)}
                                dateFormat="dd-MM-yyyy"
                                className="form-control"
                                value={
                                  doa
                                    ? moment(doa, "dd-MM-YYYY").toDate()
                                    : null
                                }
                                showYearDropdown
                                showMonthDropdown
                                placeholderText="dd-mm-yyyy"
                                maxDate={new Date()}
                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                onClick={(e) => e.preventDefault()} // Preven
                              />
                            </div>
                            {errors.doa && (
                              <div className="error-message">{errors.doa}</div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>E-Mail ID *</label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              data-val="true"
                              id=""
                              placeholder="E-Mail ID *"
                              type="text"
                              value={email}
                              onChange={(event) => setEmail(event.target.value)}
                            />
                          </div>
                          {errors.email && (
                            <div className="error-message">{errors.email}</div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Mobile No. *</label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              maxLength="10"
                              minLength="10"
                              placeholder="Mobile No."
                              type="text"
                              value={mobile}
                              onChange={(event) => {
                                const input = event.target.value;
                                if (/^\d*$/.test(input)) {
                                  setMobile(input);
                                }
                              }}
                            />
                          </div>
                          {errors.mobile && (
                            <div className="error-message">{errors.mobile}</div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <div className="input-checkbox">
                              <input
                                className="checkbox-inline mb-10 cllrs"
                                data-val="true"
                                type="checkbox"
                                checked={isSameAsMobile}
                                onChange={handleCheckboxChange}
                              />
                              <label className="checkbox-inline cllrs check-no">
                                If Whatsapp No. is same as Mobile No.
                              </label>
                            </div>
                            <input
                              maxLength="10"
                              className="form-control"
                              minLength="10"
                              name="mobileWhatsApp"
                              placeholder="Whatsapp Number"
                              type="text"
                              value={mobileWhatsApp}
                              onChange={(event) => {
                                const input = event.target.value;
                                if (/^\d*$/.test(input)) {
                                  setMobileWhatsApp(input);
                                }
                              }}
                              disabled={isSameAsMobile}
                            />
                          </div>
                          {errors.mobileWhatsApp && (
                            <div className="error-message">
                              {errors.mobileWhatsApp}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <h4 className="midhead">
                            Permanent Address<span>*</span>{" "}
                          </h4>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>House / Flat / Block No. *</label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              placeholder="House / Flat / Block No.  *"
                              maxLength="70"
                              type="text"
                              value={addressPer1}
                              onChange={(event) =>
                                setAddressPer1(event.target.value)
                              }
                            />
                          </div>
                          {errors.addressPer1 && (
                            <div className="error-message">
                              {errors.addressPer1}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Street / Colony / Locality Name *</label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              id=""
                              maxLength="70"
                              placeholder="Street / Colony / Locality Name *"
                              type="text"
                              value={addressPer2}
                              onChange={(event) =>
                                setAddressPer2(event.target.value)
                              }
                            />
                          </div>
                          {errors.addressPer2 && (
                            <div className="error-message">
                              {errors.addressPer2}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Landmark</label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              id=""
                              maxLength="70"
                              placeholder="Landmark"
                              type="text"
                              value={addressPer3}
                              onChange={(event) =>
                                setAddressPer3(event.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>State *</label>
                            <select
                              className="form-control2"
                              onChange={handleStateChangePer}
                            >
                              <option value="0">
                                {statePer ? statePer : "Select"}
                              </option>
                              {stateFetchPer.map((item, index) => {
                                return (
                                  <>
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {errors.statePer && (
                            <div className="error-message">
                              {errors.statePer}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>District *</label>
                            <select
                              className="form-control2"
                              onChange={handleDistrictChangePer}
                            >
                              <option value="0">
                                {districtPer ? districtPer : "Select"}
                              </option>
                              {districtFetchPer.map((item, index) => {
                                return (
                                  <>
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {errors.districtPer && (
                            <div className="error-message">
                              {errors.districtPer}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>City *</label>
                            <select
                              className="form-control2"
                              value={cityPer}
                              onChange={(event) => {
                                setCityPer(event.target.value);
                                // Step 2: Find and set the selected city's ID
                                const selectedCityPer = cityFetchPer.find(
                                  (item) => item.name === event.target.value
                                );
                                setCityPerId(
                                  selectedCityPer ? selectedCityPer.id : ""
                                );
                              }}
                            >
                              <option value="0">
                                {cityPer ? cityPer : "Select"}
                              </option>
                              {cityFetchPer.map((item, index) => {
                                return (
                                  <>
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {errors.cityPer && (
                            <div className="error-message">
                              {errors.cityPer}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Pin Code *</label>
                            <input
                              OnkeyPress="return isNumberKey1(event)"
                              autoComplete="nope"
                              className="form-control"
                              maxLength="6"
                              minLength="6"
                              placeholder="Pin Code  *"
                              type="text"
                              value={pincodePer}
                              onChange={(event) => {
                                const input = event.target.value;
                                if (/^\d*$/.test(input)) {
                                  setPincodePer(input);
                                }
                              }}
                            />
                          </div>
                          {errors.pincodePer && (
                            <div className="error-message">
                              {errors.pincodePer}
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <h4 className="midhead">
                            Delivery Address<span>*</span>{" "}
                          </h4>
                          <input
                            className="checkbox-inline mb-10 cllrs"
                            id="deliveryCheckbox"
                            type="checkbox"
                            checked={deliverySameAsPermanent}
                            onChange={handleCheckboxChangeAdd}
                          />
                          &nbsp; If Delivery address is same as Permanent
                          address
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>House / Flat / Block No. *</label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              id=""
                              maxLength="30"
                              name=""
                              placeholder="House / Flat / Block No. *"
                              type="text"
                              value={address1}
                              onChange={(event) =>
                                setAddress1(event.target.value)
                              }
                              disabled={deliverySameAsPermanent}
                            />
                          </div>
                          {errors.address1 && (
                            <div className="error-message">
                              {errors.address1}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Street / Colony / Locality Name *</label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              id=""
                              maxLength="30"
                              name=""
                              placeholder="Street / Colony / Locality Name  *"
                              type="text"
                              value={address2}
                              onChange={(event) =>
                                setAddress2(event.target.value)
                              }
                              disabled={deliverySameAsPermanent}
                            />
                          </div>
                          {errors.address2 && (
                            <div className="error-message">
                              {errors.address2}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Landmark</label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              id=""
                              maxLength="30"
                              name=""
                              placeholder="Landmark"
                              type="text"
                              value={address3}
                              onChange={(event) =>
                                setAddress3(event.target.value)
                              }
                              disabled={deliverySameAsPermanent}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>State *</label>
                            <select
                              className="form-control2"
                              onChange={handleStateChange}
                              disabled={deliverySameAsPermanent}
                            >
                              {deliverySameAsPermanent ? (
                                <option value={statePer}>{statePer}</option>
                              ) : (
                                <>
                                  <option value="0">
                                    {state !== "" ? state : "Select"}
                                  </option>
                                  {stateFetch.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                          {errors.state && (
                            <div className="error-message">{errors.state}</div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>District *</label>
                            <select
                              className="form-control2"
                              onChange={handleDistrictChange}
                              disabled={deliverySameAsPermanent}
                            >
                              {deliverySameAsPermanent ? (
                                <option value={districtPer}>
                                  {districtPer}
                                </option>
                              ) : (
                                <>
                                  <option value="0">
                                    {district !== "" ? district : "Select"}
                                  </option>
                                  {districtFetch.map((item, index) => (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                          {errors.district && (
                            <div className="error-message">
                              {errors.district}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>City *</label>
                            <select
                              className="form-control2"
                              value={city}
                              onChange={(event) => {
                                setCity(event.target.value);
                                // Step 2: Find and set the selected city's ID
                                const selectedCity = cityFetch.find(
                                  (item) => item.name === event.target.value
                                );
                                setCityId(selectedCity ? selectedCity.id : "");
                              }}
                              disabled={deliverySameAsPermanent}
                            >
                              <option value="0">
                                {city ? city : "Select"}
                              </option>
                              {cityFetch.map((item, index) => {
                                return (
                                  <>
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                          {errors.city && (
                            <div className="error-message">{errors.city}</div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Pin Code *</label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              data-val="true"
                              id=""
                              maxLength="6"
                              minLength="6"
                              name=""
                              placeholder="Pin Code  *"
                              type="text"
                              value={pincode}
                              onChange={(event) => {
                                const input = event.target.value;
                                if (/^\d*$/.test(input)) {
                                  setPincode(input);
                                }
                              }}
                              disabled={deliverySameAsPermanent}
                            />
                          </div>
                          {errors.pincode && (
                            <div className="error-message">
                              {errors.pincode}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row clearfix mt-15">
                        <div className="col-md-12 col-xs-12">
                          <h4 className="midhead">Upload ID Proof</h4>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Upload Photo *</label>
                            {photo !== "" ? (
                              <Link to={photo} target="_blank">
                                <span>
                                  <i className="fa fa-eye">View</i>
                                </span>
                              </Link>
                            ) : (
                              ""
                            )}
                            <input
                              accept="image/png, image/jpeg"
                              className="form-control"
                              id=""
                              name=""
                              type="file"
                              onChange={handlePhoto}
                            />
                          </div>
                          {errors.photo && (
                            <div className="error-message">{errors.photo}</div>
                          )}
                        </div>

                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Upload Aadhar Card Front*</label>
                            {aadharFrontUrl !== "" ? (
                              <Link to={aadharFrontUrl} target="_blank">
                                <span>
                                  <i className="fa fa-eye">View</i>
                                </span>
                              </Link>
                            ) : (
                              ""
                            )}
                            <input
                              className="form-control"
                              id=""
                              name=""
                              type="file"
                              onChange={handleAadharFront}
                            />
                            {/* <span className="ff">Front Copy</span> */}
                          </div>
                          {errors.aadharFrontUrl && (
                            <div className="error-message">
                              {errors.aadharBackUrl}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Upload Aadhar Card Back*</label>
                            {aadharBackUrl !== "" ? (
                              <Link to={aadharBackUrl} target="_blank">
                                <span>
                                  <i className="fa fa-eye">View</i>
                                </span>
                              </Link>
                            ) : (
                              ""
                            )}
                            <input
                              className="form-control"
                              id=""
                              name=""
                              type="file"
                              onChange={handleAadharBack}
                            />
                            {/* <span className="ff">Back Copy</span> */}
                          </div>
                          {errors.aadharBackUrl && (
                            <div className="error-message">
                              {errors.aadharBackUrl}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Aadhar No. *</label>
                            <input
                              OnkeyPress="return isNumberKey1(event)"
                              autoComplete="nope"
                              className="form-control"
                              maxLength="12"
                              minLength="12"
                              name="AadharNo"
                              placeholder="Aadhar No."
                              type="text"
                              value={aadharNumber}
                              onChange={(event) =>
                                setAadharNumber(event.target.value)
                              }
                            />
                          </div>
                          {errors.aadharNumber && (
                            <div className="error-message">
                              {errors.aadharNumber}
                            </div>
                          )}
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Upload PAN Card</label>
                            {panFrontUrl !== "" ? (
                              <Link to={panFrontUrl} target="_blank">
                                <span>
                                  <i className="fa fa-eye">View</i>
                                </span>
                              </Link>
                            ) : (
                              ""
                            )}
                            <input
                              className="form-control"
                              id=""
                              name=""
                              type="file"
                              onChange={handlePanCard}
                            />
                          </div>
                        </div>
                        {/* {errors.panFrontUrl && (
                          <div className="error-message">
                            {errors.panFrontUrl}
                          </div>
                        )} */}
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label> PAN No.</label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              id=""
                              maxLength="10"
                              minLength="10"
                              name=""
                              placeholder="PAN No."
                              type="text"
                              value={panNumber}
                              onChange={(event) =>
                                setPanNumber(event.target.value.toUpperCase())
                              }
                            />
                          </div>
                          {/* {errors.panNumber && (
                            <div className="error-message">
                              {errors.panNumber}
                            </div>
                          )} */}
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <h4 className="midhead">Influencer Bank Details </h4>
                        </div>

                        <div
                          className="bankdetails col-md-12 col-xs-12"
                          id="bankdetails"
                        >
                          <div className="col-md-3 col-sm-12 col-xs-12">
                            <div className="form-group has-float-label style-two">
                              <label>Account Holder Name</label>
                              <input
                                autoComplete="nope"
                                className="form-control"
                                placeholder="Account Holder Name"
                                maxLength="35"
                                type="text"
                                value={holderName}
                                onChange={(event) => {
                                  const input = event.target.value;
                                  if (/^[a-zA-Z\s]*$/.test(input)) {
                                    // Only alphabetic characters
                                    setHolderName(input);
                                  }
                                }}
                              />
                            </div>
                            {errors.holderName && (
                              <div className="error-message">
                                {errors.holderName}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 col-sm-12 col-xs-12">
                            <div className="form-group has-float-label style-two">
                              <label>Account Type</label>
                              <select
                                className="form-control2"
                                data-val="true"
                                data-val-required="Account Type Required"
                                id=""
                                style={{ alignItem: "center" }}
                                value={accountType}
                                onChange={(event) =>
                                  setAccountType(event.target.value)
                                }
                              >
                                <option value="">Select Account Type</option>
                                <option value="Current account">
                                  Current account
                                </option>
                                <option value="Savings account">
                                  Savings account
                                </option>
                                {/* <option value="Salary account">Salary account</option> */}
                              </select>
                            </div>
                            {errors.accountType && (
                              <div className="error-message">
                                {errors.accountType}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 col-sm-12 col-xs-12">
                            <div className="form-group has-float-label style-two">
                              <label>Bank Name </label>
                              <select
                                autoComplete="off"
                                className="form-control2"
                                data-val="true"
                                data-val-required="Bank Name Required"
                                id="BankName"
                                name="BankName"
                                value={bankName}
                                onChange={(event) =>
                                  setBankName(event.target.value)
                                }
                              >
                                <option selected="selected" value="">
                                  Select Bank Name
                                </option>
                                <option value="Allahabad Bank">
                                  {" "}
                                  Allahabad Bank
                                </option>
                                <option value="The Krur Vysya Bank Limited">
                                  The Krur Vysya Bank Limited
                                </option>
                                <option value="Airtel Payments Bank">
                                  Airtel Payments Bank
                                </option>
                                <option value="Apna Sahakari Bank Ltd">
                                  Apna Sahakari Bank Ltd
                                </option>
                                <option value="Andhra Pragathi Grameena Bank">
                                  Andhra Pragathi Grameena Bank
                                </option>
                                <option value="Andhra Pradesh Grameena Vikas Bank">
                                  Andhra Pradesh Grameena Vikas Bank
                                </option>
                                <option value="Arunachal Pradesh Rural Bank">
                                  Arunachal Pradesh Rural Bank
                                </option>
                                <option value="Aryavart Bank">
                                  Aryavart Bank
                                </option>
                                <option value="Assam Gramin Vikash Bank">
                                  Assam Gramin Vikash Bank
                                </option>
                                <option value="Au Small Finance Bank">
                                  Au Small Finance Bank
                                </option>
                                <option value="Axis Bank">Axis Bank</option>
                                <option value="Bandhan Bank">
                                  Bandhan Bank
                                </option>
                                <option value="Bank of Baroda">
                                  Bank of Baroda
                                </option>
                                <option value="Bank of India">
                                  Bank of India
                                </option>
                                <option value="Bank of Maharashtra">
                                  Bank of Maharashtra
                                </option>
                                <option value="Bangiya Gramin Vikas Bank">
                                  Bangiya Gramin Vikas Bank
                                </option>
                                <option value="Baroda Gujarat Gramin Bank">
                                  Baroda Gujarat Gramin Bank
                                </option>
                                <option value="Baroda Rajasthan Kshetriya Gramin Bank">
                                  Baroda Rajasthan Kshetriya Gramin Bank
                                </option>
                                <option value="Baroda UP Bank">
                                  Baroda UP Bank
                                </option>
                                <option value="Canara Bank">Canara Bank</option>
                                <option value="Capital Small Finance Bank">
                                  Capital Small Finance Bank
                                </option>
                                <option value="Central Bank of India">
                                  Central Bank of India
                                </option>
                                <option value="Chaitanya Godavari Grameena Bank">
                                  Chaitanya Godavari Grameena Bank
                                </option>
                                <option value="Chhattisgarh Rajya Gramin Bank">
                                  Chhattisgarh Rajya Gramin Bank
                                </option>
                                <option value="CSB Bank">CSB Bank</option>
                                <option value="City Union Bank">
                                  City Union Bank
                                </option>
                                <option value="Corporation Bank">
                                  Corporation Bank
                                </option>
                                <option value="Dakshin Bihar Gramin Bank">
                                  Dakshin Bihar Gramin Bank
                                </option>
                                <option value="DCB Bank">DCB Bank</option>
                                <option value="Dhanlaxmi Bank">
                                  Dhanlaxmi Bank
                                </option>
                                <option value="Ellaquai Dehati Bank">
                                  Ellaquai Dehati Bank
                                </option>
                                <option value="Equitas Small Finance Bank">
                                  Equitas Small Finance Bank
                                </option>
                                <option value="ESAF Small Finance Bank">
                                  ESAF Small Finance Bank
                                </option>
                                <option value="Federal Bank">
                                  Federal Bank
                                </option>
                                <option value="Fincare Small Finance Bank">
                                  Fincare Small Finance Bank
                                </option>
                                <option value="Fino Payments Bank">
                                  Fino Payments Bank
                                </option>
                                <option value="HDFC Bank">HDFC Bank</option>
                                <option value="Himachal Pradesh Gramin Bank">
                                  Himachal Pradesh Gramin Bank
                                </option>
                                <option value="IDBI Bank">IDBI Bank</option>
                                <option value="Indian Bank">Indian Bank</option>
                                <option value="India Post Payments Bank">
                                  India Post Payments Bank
                                </option>
                                <option value="Indian Overseas Bank">
                                  Indian Overseas Bank
                                </option>
                                <option value="ICICI Bank">ICICI Bank</option>
                                <option value="Induslnd Bank">
                                  Induslnd Bank
                                </option>
                                <option value="IDFC First Bank">
                                  IDFC First Bank
                                </option>
                                <option value="Jammu &amp; Kashmir Bank">
                                  Jammu &amp; Kashmir Bank
                                </option>
                                <option value="Jana Small Finance Bank">
                                  Jana Small Finance Bank
                                </option>
                                <option value="Janata Sahakari Bank">
                                  Janata Sahakari Bank
                                </option>
                                <option value="J&amp;K Grameen Bank">
                                  J&amp;K Grameen Bank
                                </option>
                                <option value="Jharkhand Rajya Gramin Bank">
                                  Jharkhand Rajya Gramin Bank
                                </option>
                                <option value="Janaseva Sahakari Bank Ltd">
                                  Janaseva Sahakari Bank Ltd
                                </option>
                                <option value="Karnataka Gramin Bank">
                                  Karnataka Gramin Bank
                                </option>
                                <option value="Karnataka Bank Ltd">
                                  Karnataka Bank Ltd
                                </option>
                                <option value="Karnataka Vikas Grameena Bank">
                                  Karnataka Vikas Grameena Bank
                                </option>
                                <option value="Kerala Gramin Bank">
                                  Kerala Gramin Bank
                                </option>
                                <option value="Kotak Mahindra Bank">
                                  Kotak Mahindra Bank
                                </option>
                                <option value="Lakshmi Vilas Bank">
                                  Lakshmi Vilas Bank
                                </option>
                                <option value="Madhya Pradesh Gramin Bank">
                                  Madhya Pradesh Gramin Bank
                                </option>
                                <option value="Madhyanchal Gramin Bank">
                                  Madhyanchal Gramin Bank
                                </option>
                                <option value="Maharashtra Gramin Bank">
                                  Maharashtra Gramin Bank
                                </option>
                                <option value="Manipur Rural Bank">
                                  Manipur Rural Bank
                                </option>
                                <option value="Meghalaya Rural Bank">
                                  Meghalaya Rural Bank
                                </option>
                                <option value="Mizoram Rural Bank">
                                  Mizoram Rural Bank
                                </option>
                                <option value="Nainital Bank">
                                  Nainital Bank
                                </option>
                                <option value="NSDL Payment Bank">
                                  NSDL Payment Bank
                                </option>
                                <option value="Bharat Co-Operative Bank">
                                  Bharat Co-Operative Bank
                                </option>
                                <option value="Nagaland Rural Bank">
                                  Nagaland Rural Bank
                                </option>
                                <option value="North East Small Finance Bank">
                                  North East Small Finance Bank
                                </option>
                                <option value="NKGSB Co-Op Bank Ltd">
                                  NKGSB Co-Op Bank Ltd
                                </option>
                                <option value="Odisha Gramya Bank">
                                  Odisha Gramya Bank
                                </option>
                                <option value="Paytm Payments Bank">
                                  Paytm Payments Bank
                                </option>
                                <option value="Punjab &amp; Sind Bank">
                                  Punjab &amp; Sind Bank
                                </option>
                                <option value="Punjab National Bank">
                                  Punjab National Bank
                                </option>
                                <option value="Paschim Banga Gramin Bank">
                                  Paschim Banga Gramin Bank
                                </option>
                                <option value="Prathama UP Gramin Bank">
                                  Prathama UP Gramin Bank
                                </option>
                                <option value="Puduvai Bharathiar Grama Bank">
                                  Puduvai Bharathiar Grama Bank
                                </option>
                                <option value="Punjab Gramin Bank">
                                  Punjab Gramin Bank
                                </option>
                                <option value="Purvanchal Gramin Bank">
                                  Purvanchal Gramin Bank
                                </option>
                                <option value="RBL Bank">RBL Bank</option>
                                <option value="Rajasthan Marudhara Gramin Bank">
                                  Rajasthan Marudhara Gramin Bank
                                </option>
                                <option value="State Bank of India">
                                  State Bank of India
                                </option>
                                <option value="State Bank of Bikaner & Jaipur">
                                  State Bank of Bikaner & Jaipur
                                </option>
                                <option value="Saptagiri Grameena Bank">
                                  Saptagiri Grameena Bank
                                </option>
                                <option value="Sarva Haryana Gramin Bank">
                                  Sarva Haryana Gramin Bank
                                </option>
                                <option value="Saurashtra Gramin Bank">
                                  Saurashtra Gramin Bank
                                </option>
                                <option value="SVC Co-Operative Bank Ltd">
                                  SVC Co-Operative Bank Ltd
                                </option>
                                <option value="Saraswat Co-Operative Bank Ltd">
                                  Saraswat Co-Operative Bank Ltd
                                </option>
                                <option value="Madhya Bihar Gramin Bank">
                                  Madhya Bihar Gramin Bank
                                </option>
                                <option value="South Indian Bank">
                                  South Indian Bank
                                </option>
                                <option value="Suryoday Small Finance Bank">
                                  Suryoday Small Finance Bank
                                </option>
                                <option value="Shivalik Small Finance Bank">
                                  Shivalik Small Finance Bank
                                </option>
                                <option value="Tamilnad Mercantile Bank">
                                  Tamilnad Mercantile Bank
                                </option>
                                <option value="Tamil Nadu Grama Bank">
                                  Tamil Nadu Grama Bank
                                </option>
                                <option value="Telangana Grameena Bank">
                                  Telangana Grameena Bank
                                </option>
                                <option value="Tripura Gramin Bank">
                                  Tripura Gramin Bank
                                </option>
                                <option value="THE COSMOS CO-OPERATIVE BANK LTD">
                                  THE COSMOS CO-OPERATIVE BANK LTD
                                </option>
                                <option value="UCO Bank">UCO Bank</option>
                                <option value="Union Bank of India">
                                  Union Bank of India
                                </option>
                                <option value="Unity Small Finance Bank">
                                  Unity Small Finance Bank
                                </option>
                                <option value="Utkal Grameen bank">
                                  Utkal Grameen bank
                                </option>
                                <option value="Uttar Bihar Gramin Bank">
                                  Uttar Bihar Gramin Bank
                                </option>
                                <option value="Uttarakhand Gramin Bank">
                                  Uttarakhand Gramin Bank
                                </option>
                                <option value="Uttarbanga Kshetriya Gramin Bank">
                                  Uttarbanga Kshetriya Gramin Bank
                                </option>
                                <option value="Utkarsh Small Finance Bank">
                                  Utkarsh Small Finance Bank
                                </option>
                                <option value="Ujjivan Small Finance Bank">
                                  Ujjivan Small Finance Bank
                                </option>
                                <option value="Vidharbha Konkan Gramin Bank">
                                  Vidharbha Konkan Gramin Bank
                                </option>
                                <option value="YES Bank">YES Bank</option>
                              </select>
                              <span
                                className="field-validation-valid errormsgr"
                                data-valmsg-for="BankName"
                                data-valmsg-replace="true"
                                style={{ color: "red" }}
                              ></span>
                            </div>
                            {errors.bankName && (
                              <div className="error-message">
                                {errors.bankName}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 col-sm-12 col-xs-12">
                            <div className="form-group has-float-label style-two">
                              <label>Account Number </label>
                              <input
                                autoComplete="nope"
                                className="form-control"
                                data-val="true"
                                data-val-required="Account Number Required"
                                id="txtacntno"
                                maxLength="25"
                                name="AccountNumber"
                                placeholder="Account Number"
                                type="text"
                                value={accountNo}
                                onChange={(event) =>
                                  setAccountNo(event.target.value)
                                }
                              />
                            </div>
                            {errors.accountNo && (
                              <div className="error-message">
                                {errors.accountNo}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 col-sm-12 col-xs-12">
                            <div className="form-group has-float-label style-two">
                              <label>Branch Name</label>
                              <input
                                id=""
                                name=""
                                className="form-control"
                                placeholder="Branch Name"
                                maxLength="50"
                                type="text"
                                value={branch}
                                onChange={(event) =>
                                  setBranch(event.target.value)
                                }
                              />
                            </div>
                            {errors.branch && (
                              <div className="error-message">
                                {errors.branch}
                              </div>
                            )}
                          </div>
                          <div className="col-md-3 col-sm-12 col-xs-12">
                            <div className="form-group has-float-label style-two">
                              <label>IFSC Code</label>
                              <input
                                autoComplete="nope"
                                className="form-control"
                                maxLength="11"
                                minLength="11"
                                placeholder="IFSC Code"
                                type="text"
                                value={ifsc}
                                onChange={(event) =>
                                  setIfsc(event.target.value.toUpperCase())
                                }
                              />
                            </div>
                            {errors.ifsc && (
                              <div className="error-message">{errors.ifsc}</div>
                            )}
                          </div>
                          <div className="col-md-3 col-sm-12 col-xs-12">
                            <div className="form-group has-float-label style-two">
                              <label>Upload Cheque or Passbook </label>
                              <Link to={cancelChk} target="_blank">
                                <span>
                                  <i className="fa fa-eye">View</i>
                                </span>
                              </Link>
                              <input
                                className="form-control"
                                id=""
                                name=""
                                type="file"
                                onChange={handleCheque}
                              />
                            </div>
                            {errors.cancelChk && (
                              <div className="error-message">
                                {errors.cancelChk}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12 col-xs-12">
                          <h4 className="midhead">Influencer UPI Details </h4>
                        </div>

                        <div className=" col-md-12 col-xs-12" id="upidetails">
                          <div className="col-md-3 col-sm-12 col-xs-12">
                            <div className="form-group has-float-label style-two">
                              <label>UPI ID </label>
                              <input
                                autoComplete="nope"
                                className="form-control"
                                placeholder="UPI ID"
                                type="text"
                                value={upiId}
                                onChange={(event) =>
                                  setUpiId(event.target.value)
                                }
                              />
                            </div>
                            {/* {errors.upiId && (
															<div className="error-message">{errors.upiId}</div>
														)} */}
                          </div>
                          <div className="col-md-3 col-sm-12 col-xs-12">
                            <div className="form-group has-float-label style-two">
                              <label>UPI ID Screenshot </label>
                              <Link to={upiIdScreen} target="_blank">
                                <span>
                                  <i class="fa fa-eye">View</i>
                                </span>
                              </Link>
                              <input
                                className="form-control"
                                id=""
                                type="file"
                                onChange={handleUpiIdScreen}
                              />
                            </div>
                            {/* {errors.upiIdScreen && (
															<div className="error-message">{errors.upiIdScreen}</div>
														)} */}
                          </div>
                        </div>

                        {/* <div className="col-md-12 col-sm-12 col-xs-12">
													<div className="form-group has-float-label style-two">
														<div className="h34">
															<label className="radio-inline">
																<input id="chckbanking" name="PaymentMode" type="radio" checked={bankDetailShow === 'bankdetails'} onChange={(event) => {
																	if (event.target.checked) {
																		setBankDetailShow('bankdetails');
																		setUpiId('');
																		setUpiIdScreen('')
																	}
																}} />
																<span> Bank Details</span>
															</label>
															<label className="radio-inline">
																<input id="chckupi" name="PaymentMode" type="radio" checked={bankDetailShow === 'upidetails'} onChange={(event) => {
																	if (event.target.checked) {
																		setBankDetailShow('upidetails');
																		setHolderName('')
																		setAccountType('')
																		setBankName('')
																		setAccountNo('')
																		setBranch('')
																		setIfsc('')
																		setCancelChk('')
																	}
																}} /> <span> UPI Details</span>
															</label>
														</div>
													</div>
												</div>
												{bankDetailShow === 'bankdetails' ?
													<div className="bankdetails col-md-12 col-xs-12" id="bankdetails">
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>Account Holder Name *</label>
																<input autoComplete="nope" className="form-control" placeholder="Account Holder Name" maxLength="35" type="text" value={holderName} onChange={(event) => {
																	const input = event.target.value;
																	if (/^[a-zA-Z\s]*$/.test(input)) {  // Only alphabetic characters
																		setHolderName(input);
																	}
																}} />
															</div>
															{errors.holderName && (
																<div className="error-message">{errors.holderName}</div>
															)}
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>Account Type *</label>
																<select className="form-control2" data-val="true" data-val-required="Account Type Required" id="" style={{ alignItem: 'center' }} value={accountType} onChange={(event) => setAccountType(event.target.value)} >
																	<option value="">Select Account Type</option>
																	<option value="Current account">Current account</option>
																	<option value="Savings account">Savings account</option>
																	<option value="Salary account">Salary account</option>
																</select>
															</div>
															{errors.accountType && (
																<div className="error-message">{errors.accountType}</div>
															)}
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>Bank Name *</label>
																<select autoComplete="off" className="form-control2" data-val="true" data-val-required="Bank Name Required" id="BankName" name="BankName" value={bankName} onChange={(event) => setBankName(event.target.value)}>
																	<option selected="selected" value="">Select Bank Name</option>
																	<option value="Airtel Payments Bank">Airtel Payments Bank</option>
																	<option value="Andhra Pragathi Grameena Bank">Andhra Pragathi Grameena Bank</option>
																	<option value="Andhra Pradesh Grameena Vikas Bank">Andhra Pradesh Grameena Vikas Bank</option>
																	<option value="Arunachal Pradesh Rural Bank">Arunachal Pradesh Rural Bank</option>
																	<option value="Aryavart Bank">Aryavart Bank</option>
																	<option value="Assam Gramin Vikash Bank">Assam Gramin Vikash Bank</option>
																	<option value="Au Small Finance Bank">Au Small Finance Bank</option>
																	<option value="Axis Bank">Axis Bank</option>
																	<option value="Bandhan Bank">Bandhan Bank</option>
																	<option value="Bank of Baroda">Bank of Baroda</option>
																	<option value="Bank of India">Bank of India</option>
																	<option value="Bank of Maharashtra">Bank of Maharashtra</option>
																	<option value="Bangiya Gramin Vikas Bank">Bangiya Gramin Vikas Bank</option>
																	<option value="Baroda Gujarat Gramin Bank">Baroda Gujarat Gramin Bank</option>
																	<option value="Baroda Rajasthan Kshetriya Gramin Bank">Baroda Rajasthan Kshetriya Gramin Bank</option>
																	<option value="Baroda UP Bank">Baroda UP Bank</option>
																	<option value="Canara Bank">Canara Bank</option>
																	<option value="Capital Small Finance Bank">Capital Small Finance Bank</option>
																	<option value="Central Bank of India">Central Bank of India</option>
																	<option value="Chaitanya Godavari Grameena Bank">Chaitanya Godavari Grameena Bank</option>
																	<option value="Chhattisgarh Rajya Gramin Bank">Chhattisgarh Rajya Gramin Bank</option>
																	<option value="CSB Bank">CSB Bank</option>
																	<option value="City Union Bank">City Union Bank</option>
																	<option value="Dakshin Bihar Gramin Bank">Dakshin Bihar Gramin Bank</option>
																	<option value="DCB Bank">DCB Bank</option>
																	<option value="Dhanlaxmi Bank">Dhanlaxmi Bank</option>
																	<option value="Ellaquai Dehati Bank">Ellaquai Dehati Bank</option>
																	<option value="Equitas Small Finance Bank">Equitas Small Finance Bank</option>
																	<option value="ESAF Small Finance Bank">ESAF Small Finance Bank</option>
																	<option value="Federal Bank">Federal Bank</option>
																	<option value="Fincare Small Finance Bank">Fincare Small Finance Bank</option>
																	<option value="Fino Payments Bank">Fino Payments Bank</option>
																	<option value="HDFC Bank">HDFC Bank</option>
																	<option value="Himachal Pradesh Gramin Bank">Himachal Pradesh Gramin Bank</option>
																	<option value="IDBI Bank">IDBI Bank</option>
																	<option value="Indian Bank">Indian Bank</option>
																	<option value="India Post Payments Bank">India Post Payments Bank</option>
																	<option value="Indian Overseas Bank">Indian Overseas Bank</option>
																	<option value="ICICI Bank">ICICI Bank</option>
																	<option value="Induslnd Bank">Induslnd Bank</option>
																	<option value="IDFC First Bank">IDFC First Bank</option>
																	<option value="Jammu &amp; Kashmir Bank">Jammu &amp; Kashmir Bank</option>
																	<option value="Jana Small Finance Bank">Jana Small Finance Bank</option>
																	<option value="J&amp;K Grameen Bank">J&amp;K Grameen Bank</option>
																	<option value="Jharkhand Rajya Gramin Bank">Jharkhand Rajya Gramin Bank</option>
																	<option value="Karnataka Gramin Bank">Karnataka Gramin Bank</option>
																	<option value="Karnataka Vikas Grameena Bank">Karnataka Vikas Grameena Bank</option>
																	<option value="Kerala Gramin Bank">Kerala Gramin Bank</option>
																	<option value="Lakshmi Vilas Bank">Lakshmi Vilas Bank</option>
																	<option value="Madhya Pradesh Gramin Bank">Madhya Pradesh Gramin Bank</option>
																	<option value="Madhyanchal Gramin Bank">Madhyanchal Gramin Bank</option>
																	<option value="Maharashtra Gramin Bank">Maharashtra Gramin Bank</option>
																	<option value="Manipur Rural Bank">Manipur Rural Bank</option>
																	<option value="Meghalaya Rural Bank">Meghalaya Rural Bank</option>
																	<option value="Mizoram Rural Bank">Mizoram Rural Bank</option>
																	<option value="Nainital Bank">Nainital Bank</option>
																	<option value="Nagaland Rural Bank">Nagaland Rural Bank</option>
																	<option value="North East Small Finance Bank">North East Small Finance Bank</option>
																	<option value="Odisha Gramya Bank">Odisha Gramya Bank</option>
																	<option value="Paytm Payments Bank">Paytm Payments Bank</option>
																	<option value="Punjab &amp; Sind Bank">Punjab &amp; Sind Bank</option>
																	<option value="Punjab National Bank">Punjab National Bank</option>
																	<option value="Paschim Banga Gramin Bank">Paschim Banga Gramin Bank</option>
																	<option value="Prathama UP Gramin Bank">Prathama UP Gramin Bank</option>
																	<option value="Puduvai Bharathiar Grama Bank">Puduvai Bharathiar Grama Bank</option>
																	<option value="Punjab Gramin Bank">Punjab Gramin Bank</option>
																	<option value="RBL Bank">RBL Bank</option>
																	<option value="Rajasthan Marudhara Gramin Bank">Rajasthan Marudhara Gramin Bank</option>
																	<option value="State Bank of India">State Bank of India</option>
																	<option value="Saptagiri Grameena Bank">Saptagiri Grameena Bank</option>
																	<option value="Sarva Haryana Gramin Bank">Sarva Haryana Gramin Bank</option>
																	<option value="Saurashtra Gramin Bank">Saurashtra Gramin Bank</option>
																	<option value="South Indian Bank">South Indian Bank</option>
																	<option value="Suryoday Small Finance Bank">Suryoday Small Finance Bank</option>
																	<option value="Shivalik Small Finance Bank">Shivalik Small Finance Bank</option>
																	<option value="Tamilnad Mercantile Bank">Tamilnad Mercantile Bank</option>
																	<option value="Tamil Nadu Grama Bank">Tamil Nadu Grama Bank</option>
																	<option value="Telangana Grameena Bank">Telangana Grameena Bank</option>
																	<option value="Tripura Gramin Bank">Tripura Gramin Bank</option>
																	<option value="UCO Bank">UCO Bank</option>
																	<option value="Union Bank of India">Union Bank of India</option>
																	<option value="Unity Small Finance Bank">Unity Small Finance Bank</option>
																	<option value="Utkal Grameen bank">Utkal Grameen bank</option>
																	<option value="Uttar Bihar Gramin Bank">Uttar Bihar Gramin Bank</option>
																	<option value="Uttarakhand Gramin Bank">Uttarakhand Gramin Bank</option>
																	<option value="Uttarbanga Kshetriya Gramin Bank">Uttarbanga Kshetriya Gramin Bank</option>
																	<option value="Utkarsh Small Finance Bank">Utkarsh Small Finance Bank</option>
																	<option value="Ujjivan Small Finance Bank">Ujjivan Small Finance Bank</option>
																	<option value="Vidharbha Konkan Gramin Bank">Vidharbha Konkan Gramin Bank</option>
																	<option value="YES Bank">YES Bank</option>
																</select>
																<span className="field-validation-valid errormsgr" data-valmsg-for="BankName" data-valmsg-replace="true" style={{ color: 'red' }}></span>
															</div>
															{errors.bankName && (
																<div className="error-message">{errors.bankName}</div>
															)}
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>Account Number  *</label>
																<input autoComplete="nope" className="form-control" data-val="true" data-val-required="Account Number Required" id="txtacntno" maxLength="25" name="AccountNumber" placeholder="Account Number" type="text" value={accountNo} onChange={(event) => setAccountNo(event.target.value)} />
															</div>
															{errors.accountNo && (
																<div className="error-message">{errors.accountNo}</div>
															)}
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>Branch Name *</label>
																<input id="" name="" className="form-control" placeholder="Branch Name" maxLength="50" type="text" value={branch} onChange={(event) => setBranch(event.target.value)} />
															</div>
															{errors.branch && (
																<div className="error-message">{errors.branch}</div>
															)}
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>IFSC Code *</label>
																<input autoComplete="nope" className="form-control" maxLength="11" minLength="11" placeholder="IFSC Code" type="text" value={ifsc} onChange={(event) => setIfsc(event.target.value.toUpperCase())} />
															</div>
															{errors.ifsc && (
																<div className="error-message">{errors.ifsc}</div>
															)}
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>Upload Cheque or Passbook  * </label><Link to={cancelChk} target="_blank"><span><i className="fa fa-eye">View</i></span></Link>
																<input className="form-control" id="" name="" type="file" onChange={handleCheque} />
															</div>
															{errors.cancelChk && (
																<div className="error-message">{errors.cancelChk}</div>
															)}
														</div>
													</div> :
													<div className=" col-md-12 col-xs-12" id="upidetails">
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>UPI ID *</label>
																<input autoComplete="nope" className="form-control" placeholder="UPI ID" type="text" value={upiId} onChange={(event) => setUpiId(event.target.value)} />
															</div>
															{errors.upiId && (
																<div className="error-message">{errors.upiId}</div>
															)}
														</div>
														<div className="col-md-3 col-sm-12 col-xs-12">
															<div className="form-group has-float-label style-two">
																<label>UPI ID Screenshot *</label><Link to={upiIdScreen} target="_blank"><span><i class="fa fa-eye">View</i></span></Link>
																<input className="form-control" id="" type="file" onChange={handleUpiIdScreen} />
															</div>
															{errors.upiIdScreen && (
																<div className="error-message">{errors.upiIdScreen}</div>
															)}
														</div>
													</div>
												} */}
                        <div className="col-md-12 col-xs-12">
                          <h4 className="midhead">
                            Influencer Professional Information{" "}
                          </h4>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Select Profession </label>
                            <select
                              className="form-control2"
                              style={{ alignItem: "center" }}
                              value={profession}
                              onChange={(event) => {
                                setProfession(event.target.value);
                                if (event.target.value === "contractor") {
                                  setContractorMobile("");
                                }
                              }}
                            >
                              <option value="">Select Profession</option>
                              <option value="carpenter">Carpenter</option>
                              <option value="contractor">Contractor</option>
                            </select>
                          </div>
                        </div>
                        {profession === "carpenter" ? (
                          <div className="col-md-3 col-sm-12 col-xs-12 carpenternumber">
                            <div className="form-group has-float-label style-two">
                              <label>Contractor Mobile No. </label>
                              <input
                                autoComplete="nope"
                                className="form-control"
                                maxLength="10"
                                minLength="10"
                                name=""
                                placeholder="Contractor Mobile No."
                                type="text"
                                value={contractorMobile}
                                onChange={(event) => {
                                  const input = event.target.value;
                                  if (/^\d*$/.test(input)) {
                                    setContractorMobile(input);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <div className="col-md-3 col-sm-12 col-xs-12 teamsize">
											<div className="form-group has-float-label style-two">
												<label>Team Size </label>
												<input autoComplete="nope" className="form-control" id="" maxLength="5" name="" placeholder="Team Size" type="text" value={teamsize} onChange={(event) => {
													const input = event.target.value;
													if (/^\d*$/.test(input)) {
														setTeamsize(input);
													}
												}} />
											</div>
										</div> */}
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>No. of Supervisors </label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              id=""
                              maxLength="5"
                              name=""
                              placeholder="No. of Supervisors"
                              type="text"
                              value={noOfSupervisor}
                              onChange={(event) => {
                                const input = event.target.value;
                                if (/^\d*$/.test(input)) {
                                  setNoOfSupervisor(input);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Annual Yearly Business (Rs in Lakhs) </label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              id=""
                              maxLength="5"
                              name=""
                              placeholder="Annual Yearly Business (Rs in Lakhs)"
                              type="text"
                              value={annualYearly}
                              onChange={(event) => {
                                const input = event.target.value;
                                if (/^\d*$/.test(input)) {
                                  setAnnualYearly(input);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Avg. No. of Sites Handled Yearly </label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              id=""
                              maxLength="5"
                              name=""
                              placeholder="Avg No. of Sites Handled Yearly"
                              type="text"
                              value={avgNoSite}
                              onChange={(event) => {
                                const input = event.target.value;
                                if (/^\d*$/.test(input)) {
                                  setAvgNoSite(input);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Major Brands Used</label>
                            <div className="row">
                              <div className="col-md-7 justify-content-between d-flex">
                                <label className="checkbox-inline">
                                  <input
                                    id=""
                                    type="checkbox"
                                    checked={
                                      brand.includes("Hettich") ? true : false
                                    }
                                    onChange={() => addtoBrand("Hettich")}
                                  />
                                  Hettich
                                </label>
                                <label className="checkbox-inline">
                                  <input
                                    id=""
                                    type="checkbox"
                                    checked={
                                      brand.includes("Ebco") ? true : false
                                    }
                                    onChange={() => addtoBrand("Ebco")}
                                  />
                                  Ebco
                                </label>
                                <label className="checkbox-inline">
                                  <input
                                    id=""
                                    type="checkbox"
                                    checked={
                                      brand.includes("Godrej") ? true : false
                                    }
                                    onChange={() => addtoBrand("Godrej")}
                                  />
                                  Godrej
                                </label>
                                <label className="checkbox-inline">
                                  <input
                                    id=""
                                    type="checkbox"
                                    checked={
                                      brand.includes("Dorset") ? true : false
                                    }
                                    onChange={() => addtoBrand("Dorset")}
                                  />
                                  Dorset
                                </label>
                                <label className="checkbox-inline">
                                  <input
                                    id=""
                                    type="checkbox"
                                    checked={
                                      brand.includes("Enox/Ozone")
                                        ? true
                                        : false
                                    }
                                    onChange={() => addtoBrand("Enox/Ozone")}
                                  />
                                  Enox/Ozone
                                </label>
                                <label className="checkbox-inline">
                                  <input
                                    id=""
                                    type="checkbox"
                                    checked={
                                      brand.includes("Yale") ? true : false
                                    }
                                    onChange={() => addtoBrand("Yale")}
                                  />
                                  Yale{" "}
                                </label>
                                <label className="checkbox-inline">
                                  <input
                                    id=""
                                    type="checkbox"
                                    checked={
                                      brand.includes("Dorma") ? true : false
                                    }
                                    onChange={() => addtoBrand("Dorma")}
                                  />
                                  Dorma
                                </label>
                                <label className="checkbox-inline">
                                  <input
                                    id=""
                                    type="checkbox"
                                    checked={
                                      brand.some((brands) =>
                                        brands.startsWith("Others")
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={() => addtoBrand("Others")}
                                  />
                                  Others
                                </label>
                              </div>
                              {brand.some((brands) =>
                                brands.startsWith("Others")
                              ) ? (
                                <div className="col-md-3 ">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={otherBrand}
                                    onChange={(event) =>
                                      setOtherValue(event.target.value)
                                    }
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>
                              Type and Value of Products Used on Annual Basis
                            </label>
                            <div className="type_and_value">
                              {productValue.map((value, index) => (
                                <label className="checkbox-inline">
                                  <input
                                    id=""
                                    type="checkbox"
                                    checked={
                                      product.includes(p[index]) ? true : false
                                    }
                                    onChange={() => addtoProduct(p[index])}
                                  />
                                  {p[index]}

                                  <input
                                    autoComplete="nope"
                                    className="form-control"
                                    id=""
                                    maxLength="5"
                                    key={index}
                                    type="text"
                                    value={value}
                                    onChange={(e) =>
                                      handleInputChange(index, e.target.value)
                                    }
                                  />
                                </label>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Type of Projects </label>
                            <select
                              className="form-control2"
                              value={typeofProjects}
                              onChange={(event) =>
                                setTypeofProjects(event.target.value)
                              }
                              style={{ alignItem: "center" }}
                            >
                              <option value="">Select Type of projects</option>
                              <option value="Home interiors">
                                Home interiors
                              </option>
                              <option value="Commercial">Commercial</option>
                              <option value="Home interiors,Commercial">
                                Both
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>
                              Solution Category (Does Turnkey Project)
                            </label>
                            <div>
                              <label className="checkbox-inline">
                                <input
                                  type="checkbox"
                                  checked={
                                    category.includes("Complete Kitchen")
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    addtoCategory("Complete Kitchen")
                                  }
                                />
                                Complete Kitchen
                              </label>
                              <label className="checkbox-inline">
                                <input
                                  type="checkbox"
                                  checked={
                                    category.includes("Wardrobe units")
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    addtoCategory("Wardrobe units")
                                  }
                                />
                                Wardrobe units{" "}
                              </label>
                              <label className="checkbox-inline">
                                <input
                                  type="checkbox"
                                  checked={
                                    category.includes("Doors") ? true : false
                                  }
                                  onChange={() => addtoCategory("Doors")}
                                />
                                Doors
                              </label>
                              <label className="checkbox-inline">
                                <input
                                  type="checkbox"
                                  checked={
                                    category.includes("Fabricator-GF")
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    addtoCategory("Fabricator-GF")
                                  }
                                />
                                Fabricator-GF
                              </label>
                              <label className="checkbox-inline">
                                <input
                                  type="checkbox"
                                  checked={
                                    category.includes("Shower enclosure")
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    addtoCategory("Shower enclosure")
                                  }
                                />
                                Shower enclosure
                              </label>
                              <label className="checkbox-inline">
                                <input
                                  type="checkbox"
                                  checked={
                                    category.includes(
                                      "Glass partition and solution"
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={() =>
                                    addtoCategory(
                                      "Glass partition and solution"
                                    )
                                  }
                                />
                                Glass partition and solution
                              </label>
                              <label className="checkbox-inline">
                                <input
                                  type="checkbox"
                                  checked={
                                    category.includes("Turnkey") ? true : false
                                  }
                                  onChange={() => addtoCategory("Turnkey")}
                                />
                                Turnkey
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>Top 3 Dealers / Retailers Buy From </label>
                            <div className="row">
                              <div className="col-md-3 col-xs-12">
                                <input
                                  autoComplete="nope"
                                  className="form-control"
                                  id=""
                                  maxLength="35"
                                  name="Dealer1"
                                  placeholder="Dealer #1"
                                  type="text"
                                  value={dealer1}
                                  onChange={(event) => {
                                    const input = event.target.value;
                                    if (/^[a-zA-Z\s]*$/.test(input)) {
                                      // Only alphabetic characters
                                      setDealer1(input);
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-md-3 col-xs-12">
                                <input
                                  autoComplete="nope"
                                  className="form-control"
                                  id=""
                                  maxLength="35"
                                  name="Dealer2"
                                  placeholder="Dealer #2"
                                  type="text"
                                  value={dealer2}
                                  onChange={(event) => {
                                    const input = event.target.value;
                                    if (/^[a-zA-Z\s]*$/.test(input)) {
                                      setDealer2(input);
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-md-3 col-xs-12">
                                <input
                                  autoComplete="nope"
                                  className="form-control"
                                  id=""
                                  maxLength="35"
                                  name="Dealer3"
                                  placeholder="Dealer #3"
                                  type="text"
                                  value={dealer3}
                                  onChange={(event) => {
                                    const input = event.target.value;
                                    if (/^[a-zA-Z\s]*$/.test(input)) {
                                      setDealer3(input);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-12">
                          <div className="form-group has-float-label style-two">
                            <label>
                              {" "}
                              If Sub Dealer, Mention Sub Dealer Name
                            </label>
                            <input
                              autoComplete="nope"
                              className="form-control"
                              id=""
                              maxLength="35"
                              placeholder="Subdealer Name"
                              type="text"
                              value={subDealerName}
                              onChange={(event) =>
                                setSubDealerName(event.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xs-12 mb-15">
                          <div className="text-center">
                            <input
                              id="btnN"
                              className="btn login_btn"
                              type="submit"
                              value="Submit"
                              onClick={() => register(idData.id)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Link
        id="scrollUp"
        to=""
        className={`scroll-to-top-button ${isVisible ? "visible" : "hidden"}`}
        style={{ position: "fixed", zIndex: "2147483647" }}
        onClick={scrollToTop}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </Link>
    </>
  );
}

import React, { useState, useEffect } from 'react'
import Footer from './common/Footer'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import SalesHeader from './common/SalesHeader';
import AMHeader from './common/AMHeader';
import RMHeader from './common/RMHeader';
import axios from 'axios';
import * as Constants from "../Constants/index"
import ResponsivePagination from 'react-responsive-pagination';
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Oval from './loader/CircleLoade';
export default function VerifyAnomaly() {
	const [isVisible, setIsVisible] = useState(false);
	const [completeReg, setCompleteReg] = useState(false)
	const [isSameAsMobile, setIsSameAsMobile] = useState(false);
	const [deliverySameAsPermanent, setDeliverySameAsPermanent] = useState(false);
	const [bankDetailShow, setBankDetailShow] = useState('')
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState();
	const [searchRecord, setSearchRecord] = useState()
	const [limit] = useState(10)
	const [errors, setErrors] = useState({});
	const [status, setStatus] = useState('')
	const [loading, setLoading] = useState(false)
	const [searchUser, setSearchUser] = useState('')


	// api data
	const [results, setResults] = useState([])

	useEffect(() => {

		window.addEventListener('scroll', handleScroll);
		let token = localStorage.getItem('token');

		//rejected registration data 
		// if (currentPage !== 1) {
			anomlyData(searchUser);
		// } else {
		// 	anomlyData('');
		// }
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [currentPage, status]);

	const anomlyData = async (userName) => {
		let token = localStorage.getItem('token');
		// const searchUserVal = userName === '' ? '' : `&mobile=${userName}`
		const searchUserVal = userName ? `&mobile=${userName}` : "";
		try {
			setLoading(true)
			axios.get(Constants.baseUrl + `user/anomalyUsersBySalesTeam/list?userType=MEMBER${searchUserVal}&sortBy=createDateTime&sortAsc=true&limit=${limit}&page=${currentPage}`, {
				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
					appVersion: Constants.av,
					platform: Constants.pt,
					company: Constants.company,
					program: Constants.program,
				}
			})
				.then((res) => {
					if (res.status === 201) {
						if (res.data.code === 10001) {
							setLoading(false)
							setResults(res.data.output.results)
							setTotalPages(res.data.output.totalPages);
						} else if (res.data.code === 12002) {
							setLoading(false)
							setSearchRecord(res.data.output.results)
							setTotalPages(res.data.output.totalPages);
						} else {
							swal({
								text: res.data.msg,
								// icon: 'error',
								timer: 2000,
								buttons: false,
							})
						}
					} else {
						swal({
							text: res.data.msg,
							// icon: 'error',
							timer: 2000,
							buttons: false,
						})
					}
				})
		} catch (error) {
			console.log(error)
		}
	}

	const handleSearchQuery = (event) => {
		const inputValue = event.target.value;
		if (/^\d{0,10}$/.test(inputValue)) {
      setSearchUser(inputValue);
	  setCurrentPage(1)
    }
		if (event.target.value === '') {
			anomlyData('')
			setSearchRecord()
		}
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			anomlyData(searchUser);
		}
	};

	// scroll button functionality start
	const handleScroll = () => {
		const scrollTop = window.pageYOffset;

		if (scrollTop > 200) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};



	const handleUnblockAnomly = async (id) => {
		let token = localStorage.getItem('token');
		try {
			await axios.post(Constants.baseUrl + `user/unblockAnomalyUser/${id}`, {},{
				headers: {
					Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
					// appVersion: Constants.av,
					// platform: Constants.pt,
					// company: Constants.company,
					// program: Constants.program
				},
			})
				.then((res) => {
					if (res.status === 200) {
						if (res.data.code === 10001) {
							swal({
								text: res.data.msg,
								timer: 2000,
								buttons: false,
							});

							anomlyData(searchUser);

						} else if (res.data.code === 12001) {
							swal({
								text: res.data.msg,
								timer: 2000,
								buttons: false,
							});
						}
					} else {
						swal({
							text: res.data.msg,
							timer: 2000,
							buttons: false,
						});
					}
				});
		} catch (error) {
			console.log(error)
		}
	}
	let headerComponent;

	if (localStorage.getItem('user_type') === 'SALES') {
		headerComponent = <SalesHeader />;
	} else if (localStorage.getItem('user_type') === 'SH') {
		headerComponent = <AMHeader />;
	} else if (localStorage.getItem('user_type') === 'RO') {
		headerComponent = <RMHeader />;
	}
	return (
		<>
			{loading ? <Oval /> : null}
			{headerComponent}
			<section className="blog-area pt182">
				<div className="container">
					<div className="row">
						<div className="col-md-12 col-sm-12 col-xs-12">
							<div className="all-single-blog rpt">
								<div className="row clearfix mb-15">
									<div className="col-md-3 col-sm-12 col-xs-12">
										<div className="form-group has-float-label style-two">
											<label>Influencer Mobile</label>
											<input type="text" className="form-control" value={searchUser} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
										</div>
									</div>
									<div className="col-md-3 col-sm-12 col-xs-12">
										<div className="form-group has-float-label style-two">
											<label className="invisible" style={{ width: '100%' }}>hidden</label>
											<input id="btnsubmit" className="btn login_btn up-side" type="submit" value="Submit" disabled={searchUser.trim() === ''}
												onClick={() => anomlyData(searchUser)} />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 col-xs-12">
							<div className="all-single-blog rpt">
								<div className="row">
									<div className="col-md-12">
										<div className="carrd1">
											<div className="card-header card-header-warning">
												<h4 className="card-title">Verify Anomaly  </h4>
											</div>
											<div className="card-body table-responsive">
												<div className="card-body table-responsive">
													<div className="table table-striped mb_01">
														<table id="gridData" className="table table-bordered mytable" style={{ width: '100%' }}>
															<thead>
																<tr>
																	<th scope="col">
																		Sr.No.
																	</th>
																	<th scope="col">
																	</th>

																	<th scope="col">
																		Regional Manager
																	</th>
																	{/* <th scope="col">
																		Regional Manager Mobile
																	</th>
																	<th scope="col">
																		AM Name
																	</th>
																	<th scope="col">
																		AM Name Mobile
																	</th> */}
																	<th scope="col">
																		Sales Person Name
																	</th>
																	<th scope="col">
																		Sales Person Mobile
																	</th>
																	<th scope="col">
																		Influencer Name
																	</th>
																	<th scope="col">
																		Influencer Mobile.
																	</th>
																	<th scope="col">
																		Influencer Email
																	</th>
																	<th scope="col">
																		Added On
																	</th>
																</tr>
															</thead>
															{searchRecord === null ?
																<tbody>
																	<tr>
																		<td colSpan="12" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
																	</tr>
																</tbody> :
																<tbody>
																	{results.map((item, index) => {
																		const serialNumber = (currentPage - 1) * limit + index + 1;
																		const createDate = item[9]
                                    ? moment(item[9]).format(
                                        "DD-MM-YYYY h:mm A"
                                      )
                                    : "";
																		return (
																			<>
																				<tr>
																					<td>{serialNumber}</td>
																					{/* <td>
																						{localStorage.getItem('user_type') == "SH" &&
																						<span><Link to="" className="editlink" onClick={() => handleUnblockAnomly(item[10])}><i className="fa fa-edit"></i> Unblock </Link></span>
																						}
																					</td> */}
																					<td>{item[0]}</td>
																					{/* <td>{item[1]}</td>
																					<td>{item[2]}</td>
																					<td>{item[3]}</td> */}
																					<td>{item[4]}</td>
																					<td>{item[5]}</td>
																					<td>{item[6]}</td>
																					<td>{item[7]}</td>
																					<td>{item[8]}</td>
																					<td>{createDate}</td>
																				</tr>
																			</>
																		)
																	})}

																</tbody >}
														</table>
														{totalPages > 1 && (
															<ResponsivePagination
																current={currentPage}
																total={totalPages}
																onPageChange={(page) => setCurrentPage(page)}
															/>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</section >
			<Footer />
			<Link id="scrollUp" to="" className={`scroll-to-top-button ${isVisible ? 'visible' : 'hidden'}`} style={{ position: 'fixed', zIndex: '2147483647' }} onClick={scrollToTop}><FontAwesomeIcon icon={faChevronUp} /></Link>
		</>
	)
}
